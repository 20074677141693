<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(tipoPeticion)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                rounded
                size="32"
                :variant="`light-${getColorAvatar(data.item.tipoPeticion)}`"
              >
                <feather-icon
                  icon="ClipboardIcon"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.tipoPeticion }}
            </span>
            <b-badge
              pill
              variant="light-dark"
            >
              {{ data.item.tabla }} | id: {{ data.item.idTabla }}
            </b-badge>
          </b-media>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge, BPagination, BAvatar, BMedia, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { showToast, showError } from '@/helpers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BAvatar,
    BMedia,

    vSelect,
    BSpinner,
  },
  data: () => ({
    isBusy: false,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    items: [],
    fields: [
      { key: 'idLogOperacion', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'tipoPeticion', sortable: true },
      { key: 'fechaOperacion', thClass: 'text-center', tdClass: 'text-center', sortable: true },
    ],
    tipoPeticion: '',
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  async mounted() {
    await this.listarRegistros()
  },
  methods: {
    avatarText,
    async listarRegistros() {
      this.isBusy = true
      await store
        .dispatch('seguridad/LOG_OPERACION_FIND_ALL', {
          tipoPeticion: this.tipoPeticion,
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: 'idLogOperacion%7CDESC',
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
      this.isBusy = false
    },
    getColorAvatar(tipoPeticion) {
      if (tipoPeticion === 'UPDATE') {
        return 'warning'
      }
      if (tipoPeticion === 'POST') {
        return 'success'
      }
      if (tipoPeticion === 'DELETE') {
        return 'danger'
      }
      if (tipoPeticion === 'GET') {
        return 'primary'
      }
      return 'primary'
    },
    getDateFmt(date) {
      return moment(String(date)).format('DD/MM/YYYY')
    },
    showToast,
    showError,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.w100{
  width: 100px !important;
}
.badge{
  font-size: 75% !important;
}
</style>
